import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/services/Banner"
import Details from "../components/services/Details"
import DownloadBrochure from "../components/services/DownloadBrochure"
import Portfolio from "../components/services/Portfolio"
import ServiceForm from "../components/services/ServiceForm"
import StyledDownloadBrochure from "../components/services/style/download-brochure"
import { FaArrowDown } from "react-icons/fa"
import DgmForm from "../components/services/DgmForm"
import Header from "../components/common/Header"
import MetaTags from "react-meta-tags"
import SEO from "../components/seo"

const DigitalMarketingDetails = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, excerpt } = markdownRemark
  return (
    <Layout className="page">
      <SEO
        title={` ${frontmatter.seoTitle} | Cresol Infoserv`}
        description={
          frontmatter.description ? frontmatter.description : excerpt
        }
        keywords={frontmatter.keyWords}
      />
      {/* <Banner
        title={frontmatter.aboutService.heading}
        image={frontmatter.bannerImage}
      /> */}

      <Header title={frontmatter.title} description={frontmatter.description} />
      <Details
        about={frontmatter.aboutService}
        development={frontmatter.developmentPhase}
        image={frontmatter.bannerImage}
      />

      <DownloadBrochure
        download={frontmatter.downloadBrochure}
        description={frontmatter.brochureDescription}
      />
      {frontmatter.portfolio && <Portfolio portfolio={frontmatter.portfolio} />}
      <DgmForm />
    </Layout>
  )
}

export default DigitalMarketingDetails

export const pageQuery = graphql`
  query Query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 148)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        seoTitle
        description
        keyWords
        bannerImage {
          publicURL
        }
        aboutService {
          heading
          details {
            description
          }
          secondHeading
          SecondDetails {
            description
          }
        }
        developmentPhase {
          heading
          details {
            heading
            description
          }
        }
        downloadBrochure {
          publicURL
        }
        portfolio {
          heading
          subHeading
          portfolioList {
            image {
              publicURL
            }
            heading
            description
            link
          }
        }
        brochureDescription
      }
    }
  }
`
